.row {
  @apply flex flex-wrap -mx-4 mb-4;
}

.row-sm {
  @apply flex flex-wrap -mx-2 mb-2;
}

.row-xs {
  @apply flex flex-wrap -mx-1 mb-1;
}

.one-col-xs {
  @apply w-full px-1 mb-2;
}

.two-col {
  @apply w-full sm:w-1/2 px-4 mb-4;
}

.two-col-sm {
  @apply w-full sm:w-1/2 px-2 mb-3;
}

.two-col-xs {
  @apply w-full sm:w-1/2 px-1 mb-1;
}

.three-col {
  @apply w-full sm:w-1/2 md:w-1/3 px-4 mb-4;
}

.three-col-sm {
  @apply w-full sm:w-1/2 md:w-1/3 px-2 mb-3;
}

.three-col-xs {
  @apply w-full sm:w-1/2 md:w-1/3 px-1 mb-2;
}

.rest-of-three-col-xs {
  @apply w-full sm:w-1/2 md:w-1/3 lg:w-2/3 px-1 mb-2;
}

.four-col {
  @apply w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-4;
}

.rest-of-four-col {
  @apply w-full sm:w-1/2 md:w-2/3 lg:w-3/4 px-4 mb-4;
}

.four-col-xs {
  @apply w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-1 mb-2;
}

.rest-of-four-col-xs {
  @apply w-full sm:w-1/2 md:w-2/3 lg:w-3/4 px-1 mb-2;
}

.five-col {
  @apply w-full sm:w-1/2 md:w-1/5 px-4 mb-4;
}

.rest-of-five-col {
  @apply w-full sm:w-1/2 md:w-4/5 px-4 mb-4;
}

.five-col-xs {
  @apply w-full sm:w-1/2 md:w-1/5 px-1 mb-2;
}

.rest-of-five-col-xs {
  @apply w-full sm:w-1/2 md:w-4/5 px-1 mb-2;
}

.six-col {
  @apply w-full sm:w-1/2 md:w-1/6 px-4 mb-4;
}

.rest-of-six-col {
  @apply w-full sm:w-1/2 md:w-5/6 px-4 mb-4;
}

.six-col-xs {
  @apply w-full sm:w-1/2 md:w-1/6 px-1 mb-2;
}

.rest-of-six-col-xs {
  @apply w-full sm:w-1/2 md:w-5/6 px-1 mb-2;
}

.ellipsis {
  @apply whitespace-nowrap overflow-ellipsis overflow-hidden;
}

section.layout-with-sidebar {
  @apply flex overflow-hidden relative !important;
}

.main-view {
  @apply w-full sm:flex-1 sm:ml-60;
  /* background-color: hsl(0deg 0% 97%); */
}

.main-view-no-sidebar {
  @apply w-full h-screen;
  /* background-color: hsl(0deg 0% 97%); */
}

.sidenav-opener {
  @apply sm:hidden text-textColor bg-white rounded-full shadow-sm shadow-gray-600 p-2.5 text-center inline-flex items-center;
}

/* Page View */
.container-no-padding {
  @apply !px-0;
}

.page-header {
  @apply flex justify-between mb-1 text-textColor;
}

/*.page-header h1 {
  @apply text-xl md:text-2xl font-semibold text-zinc-700;
}*/

.page-content-bg {
  -webkit-box-shadow: 1px 1px 3px #0000004D;
  -moz-box-shadow:    1px 1px 3px #0000004D;
  box-shadow: 1px 1px 3px #0000004D;
  @apply bg-white p-4 mb-2;
}

.page-content-no-bg {
  @apply pb-6 !pt-3 !px-0;
}

/* Detail Page */
.detail-heading {
  @apply pb-4;
}

.detail-wrapper .single-detail {
  @apply flex mb-2;
}

.detail-wrapper .single-detail p {
  @apply mb-0;
}

.detail-wrapper .single-detail-heading {
  @apply w-1/2 pl-24 normal-case;
}

.detail-wrapper .single-detail-desc {
  @apply w-1/2 mb-1 pl-3;
}

/*.has-ad {
  @apply flex;
}*/

/* When there is a page with no ads then use just .page-view in a div with section as parent. */
.page-view {
  @apply w-full;
}

/*.has-ad .page-view {
  @apply w-full flex-1 h-full;
}*/

.page-ad-space-horizontal {
  @apply py-5 mb-[15px];
}

.page-ad-space-horizontal .page-ad {
  @apply w-full h-[80px];
}

.page-ad-space-vertical {
  @apply px-3 pl-6;
}

.page-ad-space-vertical .page-ad {
  @apply w-[132px] h-[400px];
}

.page-ad-space-vertical .page-ad-mt {
  @apply mt-[34px];
}

.page-ad-space-vertical .page-ad img {
  @apply h-full w-full object-fill;
}

/* Form */
.form-container {
  @apply px-4;
}

label .required {
  @apply text-inherit; 
}

.field-err-message {
  @apply text-red-600 text-[12px];
}

.btn-container {
  @apply text-center pt-5;
}

.checkbox-container {
  @apply mr-3 flex items-center;
}

.checkbox-container label {
  @apply pl-2 !mb-0 text-[11px];
}

/* Steps List */
ul.steps-list li {
  @apply text-[12px] font-semibold mb-3;
}

ul.steps-list .step {
  @apply py-1 text-left pl-1 pr-4;
}

ul.steps-list .active .step {
  @apply border border-gray-500 shadow-sm shadow-gray-300;
}

ul.steps-list li span {
  @apply px-0;
}

ul.steps-list span.number {
  @apply py-[1px] px-[6px] mr-1 border border-textColor;
  border-radius: 50%;
}

/* ----------------- Table Styles ---------------------------- */

/* table thead tr {
  @apply space-x-1 mb-2;
}

table thead th {
  @apply bg-white overflow-visible border inline-block;
}

table thead tr {
@apply flex;
}

table tbody tr td {
  @apply bg-white;
}

table tbody {
  @apply text-sm;
} */

.table-wrapper::before {
  content: '';
  position: absolute;
  height: calc(100vh - 390px);
  width: 100%;
  top: 40px;
  background-color: white;
  z-index: -1;
}

table th:first-child {
  @apply !pl-0;
}

table th:last-child {
  @apply !pr-0;
}

/* table tbody tr td {
  @apply w-2 overflow-hidden;
} */

/* ----------------------------Pagination----------------------*/
.custom-pagination ul {
  @apply inline-flex space-x-2;
}

.custom-pagination ul li {
  @apply pr-2 !ml-0 flex items-center;
}

.custom-pagination ul li a {
  @apply text-[11px] font-medium;
}

/* .custom-pagination ul .selected {
  @apply font-bold;
} */

.custom-pagination .pagination li.active a {
  @apply font-bold;
}

/* ----------------- Modal Styles ----------------------------*/

.modalBackground {
  @apply fixed inset-0 bg-neutral-100 bg-opacity-80 z-50 flex items-center overflow-hidden;
}

.modalContainer {
  @apply px-7 py-5 mx-auto my-0 bg-white shadow-sm shadow-gray-400 rounded-none;
  max-width: 90%;
  max-height: 80%;
  /* width: 75%; */
  /* height: 70%; */
  min-height: 9rem;
  overflow: auto; 
}

.modal .title h5 {
  @apply !normal-case;
}

/* ----------------- Time Picker ----------------------------*/
.react-datepicker__time-container {
  @apply !w-[200px];
}

.react-datepicker__close-icon::after {
  line-height: 0.1 !important;
  @apply bg-white text-black border border-black !important;
}

/* Datepicker custom design */
.react-datepicker {
  font-family: 'Hero New', sans-serif !important;
  @apply !rounded-none px-10;
}

.custom-timepicker {
  @apply px-0;
}

.react-datepicker__header {
  @apply !bg-white !border-0;
}

.react-datepicker__triangle {
  @apply !hidden;
}

.react-datepicker__current-month {
  @apply flex justify-between items-center px-1;
}

.react-datepicker__current-month span {
  @apply text-xs !important;
}

.react-datepicker__day-names {
  @apply py-[3px] mt-2 border border-gray-400 flex justify-between items-center !important;
}

.react-datepicker__day-name {
  line-height: 1 !important;
  @apply m-0 text-[11px] text-gray-400 font-semibold !important;
}

.react-datepicker__day {
  @apply m-0 text-[11px] font-semibold !important;
}

.react-datepicker__month {
  @apply m-0 pt-1 !important;
}

.react-datepicker__day--selected {
  @apply bg-white border border-black text-black rounded-none !important;
}

.react-datepicker__day--keyboard-selected {
  @apply border border-black rounded-none bg-white text-black !important;
}

/*Datepicker in contact*/
.inputCalendar .react-datepicker__current-month {
  @apply text-xs !important;
}

.react-datepicker-time__header {
  @apply text-xs !important;
}