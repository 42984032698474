@import "./fonts.css";
@import "./custom.css";
@import "./sidebar.css";
@import "./dashboard.css";
@import "./login.css";
@import "./dropdownBtn.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  /*In firefox, font family was not being applied to the option tag. So, we had to explicitly mention font-family in the body tag*/
  body {
    font-family: 'Hero New', sans-serif;
    background-color: hsl(0deg 0% 97%);
    @apply text-textColor min-h-screen;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    @apply font-bold capitalize;
  }

  h1,
  .h1 {
    /* @apply text-3xl md:text-4xl; */
    @apply text-[19px] font-semibold;
  }

  .h1-m {
    /* @apply text-3xl md:text-4xl; */
    @apply text-[19px] font-medium;
  }

  h2,
  .h2 {
    /* @apply text-2xl md:text-3xl; */
    @apply text-[16px] font-semibold;
  }

  .h2-m {
    /* @apply text-2xl md:text-3xl; */
    @apply text-[16px] font-medium;
  }

  h3,
  .h3 {
    /* @apply text-xl md:text-2xl; */
    @apply text-[15px] font-semibold;
  }

  .h3-m {
    /* @apply text-xl md:text-2xl; */
    @apply text-[15px] font-medium;

  }

  h4,
  .h4 {
    /* @apply text-lg md:text-xl; */
    @apply text-[13px] font-semibold;
  }

  .h4-m {
    @apply text-[13px] font-medium; 
  }

  h5,
  .h5 {
    /* @apply text-base; */
    @apply text-[12px] font-semibold;
  }

  .h5-m {
    @apply text-[12px] font-medium;
  }

  h6,
  .h6 {
    /* @apply text-sm; */
    @apply text-[11px] font-medium;
  }

  a {
    @apply text-[12px] font-medium text-textColor hover:text-primary inline-block whitespace-nowrap overflow-ellipsis overflow-hidden transition-all;
  }

  p {
    @apply text-[12px] font-medium mb-3;
  }

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  /*textarea,*/
  select {
    @apply w-full h-[26px] text-[12px] rounded-none border-gray-400 py-0 px-3 mb-3 shadow-sm shadow-gray-300 focus:border-none focus:ring-inset focus:ring-1 focus:ring-gray-400;
  }

  [type='file'] {
    @apply text-[12px];
  }

  [type='number'] {
    @apply px-1;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin-left: 5px;
  }

  textarea {
    @apply w-full text-xs rounded-none border-gray-400 py-1 px-3 mb-3 shadow-sm shadow-gray-300 focus:border-none focus:ring-inset focus:ring-1 focus:ring-gray-400; 
  }

  /* Overriding Tailwind's default placeholder css with higher specificity */
  .custom-placeholder::placeholder {
    @apply text-textColor-light opacity-100 !important; /* Opacity for Firefox */
  }

  .custom-placeholder:-ms-input-placeholder { /* Internet Explorer 10-11 */
    @apply !text-textColor-light;
   }
   
  .custom-placeholder::-ms-input-placeholder { /* Microsoft Edge */
    @apply !text-textColor-light;
  }

  select {
    @apply pr-6 text-ellipsis text-xs;
  }

  [type='checkbox'] {
    @apply w-4 h-4 rounded-none border-gray-400 text-primary-dark focus:ring-transparent shadow-sm shadow-gray-300;
  }

  [type='radio'] {
    @apply w-4 h-4 rounded-none border-gray-400 text-primary-dark focus:ring-transparent shadow-sm shadow-gray-300;
  }

  .no-input-arrows[type='number']::-webkit-inner-spin-button,
  .no-input-arrows[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }

  .no-input-arrows[type='number'] {
    -moz-appearance: textfield;
  }

  input::placeholder,
  select::placeholder,
  textarea::placeholder {
    @apply text-[12px] font-medium !text-gray-400;
  }

  label,
  .label {
    @apply capitalize font-semibold text-[12px] px-3 mb-[6px] inline-block text-textColor;
  }

  /* Select tag for table  */

  select.table-select {
    background-image: none;
    @apply h-fit px-2 mb-0 h5 border-none shadow-none focus:border-none focus:ring-0 cursor-pointer;
  }

  .table-select-wrapper::after {
    content: "";
    position: absolute;
    right: 8px;
    top: 40%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 4px 4px;
    border-color: #000000 transparent transparent transparent;
  }

  .custom-date-input::after {
    content: "";
    position: absolute;
    right: 8px;
    top: 40%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 4px 4px;
    border-color: #000000 transparent transparent transparent;
  }

  /* Input tag for table  */
  
  /* REUSEABLE CLASS: Sass like functionality. Here we had already defined "custom-placeholder" in this same file somewhere, so we can use it anywhere now. */
  input.table-input {
    @apply border-none px-2 pb-1 text-[12px] rounded-sm w-full custom-placeholder focus:outline-0;
  }

  input.table-input::placeholder {
    @apply text-textColor-light font-semibold text-[12px] opacity-100 !important; /* Opacity for Firefox */
  }

  input.table-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    @apply !text-textColor-light font-semibold text-[12px];
   }
   
  input.table-input::-ms-input-placeholder { /* Microsoft Edge */
    @apply !text-textColor-light font-semibold text-[12px];
  }

  /* ---Scrollbar---  */
  /* width */
  ::-webkit-scrollbar {
    @apply w-[8px];
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply rounded-lg shadow-inner shadow-gray-400;
  }
 
  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-gray-300 rounded-lg;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-400;
  }

}

@layer components {
  .btn {
    @apply py-0 px-8 mr-3 mb-2 text-xs text-white leading-6 rounded-none transition-all font-semibold bg-textColor;
  }

  .btn-sm {
    @apply px-6 text-xs;
  }

  .btn-lg {
    @apply py-1 px-8 text-sm;
  }

  .btn-primary {
    @apply bg-primary text-white hover:bg-primary-dark shadow-sm shadow-gray-300;
  }

  .btn-secondary {
    @apply bg-secondary text-textColor hover:bg-secondary-dark shadow-sm shadow-gray-300;
  }

  .btn-white {
    @apply bg-white text-textColor font-normal border border-gray-200 hover:bg-gray-50 shadow-sm shadow-gray-300;
  }

  .btn-link {
    /* @apply bg-transparent text-gray-800 hover:text-gray-700; */
    @apply py-0 px-2 mr-3 mb-2 bg-transparent text-[12px] font-medium text-textColor hover:text-primary inline-block transition-all;
  }

  .btn-gray {
    @apply bg-[#DBDBDB] text-white hover:bg-gray-300 shadow-sm shadow-gray-300;
  }

  .badge {
    @apply inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-gray-700 border rounded-none;
  }

  .badge-green {
    @apply border-green-500 text-green-500;
  }

  .badge-red {
    @apply border-red-500 text-red-500;
  }

  .badge-yellow {
    @apply border-yellow-500 text-yellow-500;
  }

  .badge-blue {
    @apply border-blue-500 text-blue-500;
  }

  .badge-gray {
    @apply border-gray-500 text-gray-500;
  }
}

/* .page-header h1 {
  position: relative;
  display: inline-block;
}

.page-header h1::after {
  content: "";
  position: absolute;
  right: -15px;
  top: 50%;
  width: 0;
height: 0;
border-style: solid;
border-width: 10px 6px 0 6px;
border-color: #000000 transparent transparent transparent;
} */