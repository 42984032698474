@font-face {
    font-family: 'Hero New';
    src: url('../fonts/HeroNew/HeroNew-Bold.woff2') format('woff2'),
        url('../fonts/HeroNew/HeroNew-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hero New';
    src: url('../fonts/HeroNew/HeroNew-BoldItalic.woff2') format('woff2'),
        url('../fonts/HeroNew/HeroNew-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Hero New';
    src: url('../fonts/HeroNew/HeroNew-LightItalic.woff2') format('woff2'),
        url('../fonts/HeroNew/HeroNew-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Hero New';
    src: url('../fonts/HeroNew/HeroNew-Light.woff2') format('woff2'),
        url('../fonts/HeroNew/HeroNew-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hero New';
    src: url('../fonts/HeroNew/HeroNew-Regular.woff2') format('woff2'),
        url('../fonts/HeroNew/HeroNew-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hero New';
    src: url('../fonts/HeroNew/HeroNew-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/HeroNew/HeroNew-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Hero New';
    src: url('../fonts/HeroNew/HeroNew-SemiBold.woff2') format('woff2'),
        url('../fonts/HeroNew/HeroNew-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* @font-face {
    font-family: 'Adobe Clean';
    src: url('../fonts/AdobeClean-Bold.woff2') format('woff2'),
        url('../fonts/AdobeClean-BoldIt.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Adobe Clean';
    src: url('../fonts/AdobeClean-LightIt.woff2') format('woff2'),
        url('../fonts/AdobeClean-LightIt.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Adobe Clean';
    src: url('../fonts/AdobeClean-Light.woff2') format('woff2'),
        url('../fonts/AdobeClean-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Adobe Clean';
    src: url('../fonts/AdobeClean-Bold.woff2') format('woff2'),
        url('../fonts/AdobeClean-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Adobe Clean';
    src: url('../fonts/AdobeClean-Regular.woff2') format('woff2'),
        url('../fonts/AdobeClean-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Adobe Clean';
    src: url('../fonts/AdobeClean-SemiLight.woff2') format('woff2'),
        url('../fonts/AdobeClean-SemiLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
 */