section .dashboard-section {
	@apply h-screen flex flex-col justify-center;
}

section .dashboard-section a {
	@apply w-full hover:text-inherit;
}

section .dashboard-section .two-col-xs {
	@apply px-2;
}

/* Dashboard Small box */
.dashboard-section .dashboard-smallbox {
	@apply h-[120px] flex flex-col justify-center items-center cursor-pointer;
}

.dashboard-section .dashboard-smallbox-yellow {
	background-color: hsl(48, 97%, 50%);
}

.dashboard-section .dashboard-smallbox-blue {
	background-color: hsl(211, 68%, 25%);
	@apply text-white;
}

.dashboard-smallbox .single-heading {
	@apply text-center w-[75%] overflow-hidden whitespace-normal;
}

/* Dashboard Search box */
.dashboard-section .dashboard-searchbox {
	@apply h-[120px] flex items-center cursor-pointer;
}

.dashboard-searchbox .icon-wrapper {
	@apply px-8;
}

/* .dashboard-searchbox input.dashboard-search {
	@apply border-none pr-2 py-1 text-[14px] rounded-sm w-full focus:outline-0 text-center;
}

.dashboard-searchbox input.dashboard-search:focus::placeholder {
	@apply invisible;
} */

/* .dashboard-searchbox input.dashboard-search::placeholder {
	@apply text-textColor-light font-semibold text-[14px] opacity-100 !important; /* Opacity for Firefox
} */

/* .dashboard-searchbox input.dashboard-search:-ms-input-placeholder { /* Internet Explorer 10-11
	@apply !text-textColor-light font-semibold text-[14px];
} */
   
/* .dashboard-searchbox input.dashboard-search::-ms-input-placeholder { /* Microsoft Edge 
	@apply !text-textColor-light font-semibold text-[14px];
} */

/* Dashboard Ad and Content Box  */
.dashboard-section .dashboard-adAndContentBox {
	@apply h-[120px] flex items-center;
}

.dashboard-adAndContentBox .ad-space-wrapper {
	@apply w-[80px] mx-6;
}

.dashboard-adAndContentBox .ad-space-wrapper img {
	@apply w-full object-cover;
}

.dashboard-adAndContentBox .single-heading {
	@apply text-[22px] pl-10 font-medium w-[55%] break-words;
}

/* Dashboard News Feed */
.dashboard-section .dashboard-newsFeedBox-wrapper {
	-webkit-box-shadow: 1px 1px 3px #0000004D;
  	-moz-box-shadow:    1px 1px 3px #0000004D;
	box-shadow: 1px 1px 3px #0000004D;
	@apply h-[248px] overflow-x-auto border-b border-gray-200 pb-0 bg-white;
}

.dashboard-section .dashboard-newsFeedBox {
	@apply px-0 py-0 mb-0;
}

.dashboard-newsFeedBox li {
	@apply border-b-2 border-gray-200 flex items-center py-2;
}

.dashboard-newsFeedBox .news-date {
	@apply text-[11px] font-semibold pl-4 min-w-[90px] inline-block;
}

.dashboard-newsFeedBox .news-title {
	@apply text-[11px] pl-10 inline-block w-[400px] hover:text-primary;
}