.sidebar {
    /* @apply w-60 h-full px-1 shadow-md shadow-gray-600 z-10 transition-all duration-300; */
    @apply w-60 px-1 fixed top-0 bottom-0 shadow-md shadow-gray-600 z-10 transition-all duration-300;
}

.sidebar-close {
    @apply w-0 p-0 !important;
}

.sidebar aside {
    @apply h-full pl-1;
}

.sidebar-close aside {
    @apply !hidden;
}

.sidebar .logo {
    @apply flex justify-center py-8 h-[120px];
}

.sidebar .logo .main-logo {
    @apply max-w-[60%];
}

.sidebar nav {
    @apply flex flex-col justify-between overflow-y-auto h-5/6;
}

.sidebar .nav-items,
.sidebar .action-nav-items
.sidebar .misc-nav-items {
    @apply !pr-16;
}

.sidebar .nav-items li {
    @apply border-b border-gray-300 ml-4 py-2 px-0;
}

.sidebar .nav-items a {
    @apply font-semibold hover:text-primary whitespace-nowrap overflow-ellipsis overflow-hidden transition-all;
}

.sidebar .nav-items a.active,
.sidebar .action-nav-items a.active,
.sidebar .misc-nav-items a.active {
    @apply text-primary pl-2;
}

.sidebar .ad-space {
    @apply min-h-[50px] mt-6 pl-3 flex items-center;
}

/* .sidebar .action-nav-items {
    @apply !pr-10;
} */

.sidebar .action-nav-items li {
    @apply flex items-center ml-4 py-2 px-0;
}

.sidebar .action-nav-items a {
    @apply font-semibold text-[11px] ml-2 text-textColor hover:text-primary whitespace-nowrap overflow-ellipsis overflow-hidden transition-all;
}

.sidebar .action-nav-items .open-dropdown {
    width: 120px;
    position: relative;
    z-index: 999;
}

.sidebar .action-nav-items .open-dropdown::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    height: 65px;
    background-color: white;
    z-index: -1;
    @apply shadow shadow-gray-400;
}

.sidebar .action-nav-items .lang {
    @apply pl-0;
}

.sidebar .action-nav-items .lang-selected::after {
    content: "";
    position: absolute;
    padding-left: 14px;
    top: 3px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 8.7px 5px 0;
    border-color: transparent #000 transparent transparent;
}

.sidebar .misc-nav-items {
    @apply py-6;
}

.sidebar .misc-nav-items li {
    @apply flex items-center ml-4 px-0 pb-1;
}

.sidebar .misc-nav-items a {
    @apply font-medium text-[11px] text-textColor hover:text-primary whitespace-nowrap overflow-ellipsis overflow-hidden transition-all;
}

.rounded-icon:before {
    content: "";
    @apply w-[7px] h-[7px] rounded-full absolute right-[-6px] top-[-4px] bg-red-700;
}